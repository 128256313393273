(function($){
    fn_comp_partners($);
})(jQuery);

function fn_comp_partners($) {
    const $fn = $(".comp_partners");

    if ($fn.length) {
        cssLoaded(function() {
            let $arrows = false;

            if ($fn.find("[data-slider-arrows]").length) {
                $arrows = true;
            }

            $fn.find("[data-slider]").flickity({
                contain: true,
                prevNextButtons: $arrows,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                adaptiveHeight: true
            });

            let flkty = $fn.find("[data-slider]").data('flickity');

            $fn.find("[data-slider]").on('select.flickity', function() {
                console.log();
                $fn.find("[data-slider-nav]").removeClass("mod--active").eq(flkty.selectedIndex).addClass("mod--active");
            });

            $fn.on('click', '[data-slider-nav]', function () {
                let index = $(this).parent().index();
                $(this).addClass("mod--active").closest(".wrp_content_nav").find("[data-slider-nav]").not(this).removeClass("mod--active");
                $fn.find("[data-slider]").flickity('select', index, false, true);
            });


        });
    }
}