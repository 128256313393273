(function ($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function (e) {
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function () {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function () {
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    if ($(".lib--rellax").length) {
        $.getScript(cdnjs.rellax).done(function() {
            new Rellax('.lib--rellax', {
                center: true
            });
        });
    }

    cssLoaded(function () {
        let $sticky = $("[data-sticky]");
        if ($sticky.length) {
            $sticky.each(function () {
                let $el = $(this);
                if (($el.height() + 90) > $(window).height()) {
                    $el.addClass("mod--static");
                }
            });
        }
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if (strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie")) {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
    if (window.location.hash === "#arrr") {
        $("body").addClass("mod--ship-sail");
    }

    $doc.ready(function () {
        antispam();
        antispaminquiry();
    });
        
    $('[data-medialist]').click(function(){
        $('[data-medialist]').removeClass('mod--active');
        $(this).addClass('mod--active');
    });

    $doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajax_handler(payload);
            }
        });
    });

    $doc.on('click','a[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajax_handler(payload,function() {
                sr.sync();
                fn_js_actions.process(payload);
            });
        });
    });

    let $gallery = $doc.find("[data-gallery]");

    if($gallery.length) {
        $.getScript(cdnjs.lightgallery).done(function () {
            $gallery.lightGallery({
                thumbnail: true,
                selector: ".elm_gallery_item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false
            });
        });
    }
    
    $(document).on('click', '[data-pagger]', function () {
        $('html, body').animate({
            scrollTop: $('#snippet-article').offset().top - 75
        }, 1250);
    });

    $doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();
        let $frm = $(this),
            formData = new FormData($(this)[0]);

        var formSubElem = $(this).find('button');
        formSubElem.attr('disabled','disabled');

        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajax_handler(payload, function () {
                    antispam();
                    antispaminquiry();
                    fn_js_actions.process(payload);

                    $("[data-lib-recaptcha]").lib_reCaptcha("lib-recaptcha");
                });

                setTimeout(function() {
                    formSubElem.removeAttr('disabled');
                },1000);
            }, error: function (xhr, ajaxOptions, thrownError) {
                setTimeout(function() {
                    formSubElem.removeAttr('disabled');
                },1000);
            }
        });
    });

    $doc.on('submit','form.part_form:not(.ajax)', function(e) {
        $(this).find('button').attr('disabled','disabled');
    });

    if (navigator.platform.indexOf('Mac') === -1 && navigator.platform.indexOf('iPad') === -1 && navigator.platform.indexOf('Mac') === -1) {
        $html.addClass("no-apple");
    }

    setTimeout(function() {
        $("[data-lib-recaptcha]").lib_reCaptcha("lib-recaptcha");
    },2500);

})(jQuery);